import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import {LinkListWrapper} from "../../components/link";
import {StyledLink} from "../../components/link";

const links = [
	{
		name: "No Poverty",
		path: "/SDGs/1",
	},
	{
		name: "Zero Hunger",
		path: "/SDGs/2",
	},
	{
		name: "Good Health and Wellbeing",
		path: "/SDGs/3",
	},
	{
		name: "Quality Education",
		path: "/SDGs/4",
	},
	{
		name: "Gender Equality",
		path: "/SDGs/5",
	},
	{
		name: "Clean Water and Sanitation",
		path: "/SDGs/6",
	},
	{
		name: "Affordable and Clean Energy",
		path: "/SDGs/7",
	},
	{
		name: "Decent Work and Economic Growth",
		path: "/SDGs/8",
	},
	{
		name: "Industry, Innovation and Infrastructure",
		path: "/SDGs/9",
	},
	{
		name: "Reduced Inequalities",
		path: "/SDGs/10",
	},
	{
		name: "Sustainable Cities and Communities",
		path: "/SDGs/11",
	},
	{
		name: "Responsible Consumption and Production",
		path: "/SDGs/12",
	},
	{
		name: "Climate Action",
		path: "/SDGs/13",
	},
	{
		name: "Life Below Water",
		path: "/SDGs/14",
	},
	{
		name: "Life On Land",
		path: "/SDGs/15",
	},
	{
		name: "Peace, Justice and Strong Institutions",
		path: "/SDGs/16",
	},
	{
		name: "Partnership for the Goals",
		path: "/SDGs/17",
	}
];


const SDGPage = () => {
	return (
		<Layout>
			<SEO title={"SDGs"} />
			<h1 className={"title"}>Sustainable Development Goals - SDGs</h1>
			<LinkListWrapper>
				{
					links.map(({path, name}) => (
						<li>
							<StyledLink to={path} key={name}>
								{name}
							</StyledLink>
						</li>
					))
				}
			</LinkListWrapper>
		</Layout>
	)
}

export default SDGPage
